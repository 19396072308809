<template>
  <div>
    <Navbar />
    <PageBanner />
    <Pricing class="pt-100" />
    <Subscribe />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import PageBanner from "../Pricing/PageBanner";
import Pricing from "../Pricing/PricingDetails";
import Subscribe from "../Contact/Subscribe";
import Footer from "../Layout/Footer";

export default {
  name: "PricingPageOne",
  components: {
    Navbar,
    PageBanner,
    Pricing,
    Subscribe,
    Footer,
  },
};
</script>
