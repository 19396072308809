<template>
  <div class="digital-experience-area ptb-100">
    <div class="container">
      <div class="section-title">
        <span>Functionality</span>
        <h2 style="font-size: 35px;">Popular Modules</h2>
        <div class="bar"></div>
      </div>

      <div class="digital-experience-tab">
        <div class="tabs">
          <ul class="tabs__header">
            <li
              class="tabs__header-item"
              v-for="tab in tabs"
              v-on:click="selectTab(tab.id)"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              {{ tab.title }}
            </li>
          </ul>
        </div>
        <div class="tabs__container">
          <div class="tabs__list" ref="tabsList">
            <div
              class="tabs__list-tab"
              v-for="tab in tabs"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="digital-experience-content">
                    <h3>{{ tab.title }}</h3>
                    <p>{{ tab.description }}</p>

                    <div
                      class="experience-inner-content"
                      v-for="experience in tab.experienceInner"
                      :key="experience.id"
                    >
                      <div class="icon">
                        <i :class="experience.icon"></i>
                      </div>
                      <h3>{{ experience.title }}</h3>
                      <p>{{ experience.desc }}</p>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="digital-experience-image">
                    <img :src="tab.image" alt="image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OutstandingDigitalExperience",
  data() {
    return {
      activeTab: 1,
      offsetRight: 0,

      tabs: [
        {
          id: 1,
          title: "Assessments",
          description:
            "In addition to 100+ standard frameworks, Impact IRM accommodates custom frameworks that align with your specific business needs on the fly. It's an out of the box, customizable interface that allows you to easily tailor the platform to fit your needs.",
          experienceInner: [
            {
              id: 1,
              icon: "flaticon-check",
              title: "Flexible Configurations",
              desc: "Designed for both Client/Assessor configurations as well as Company-specific configurations for internal management (departments, affiliates, subsidiaries).",
            },
            {
              id: 2,
              icon: "flaticon-check",
              title: "Report Generation",
              desc: "Customize, print or share your ROCs and POA&Ms, right from the platform.",
            },
            {
              id: 3,
              icon: "flaticon-check",
              title: "Automated Associations",
              desc: "Create tasks and request evidence that will automatically map to an associated Control.",
            },
          ],
          image: require("../../assets/images/digital-experience/digital-experience-3.png"),
        },
        {
          id: 2,
          title: "Crosswalk Dashboard",
          description:
            "While completing work for one assessment, see how close you may be to fulfilling requirements for another assessment. Choose which assessments to cross-reference and watch your progess!",
          experienceInner: [
            {
              id: 1,
              icon: "flaticon-check",
              title: "Comprehensive",
              desc: "Choose from among 100+ frameworks to cross-reference.",
            },
            {
              id: 2,
              icon: "flaticon-check",
              title: "Actionable",
              desc: "Quickly identify any other assessment framework requirements you may be close to fulfilling.",
            },
            {
              id: 3,
              icon: "flaticon-check",
              title: "Close the Gap",
              desc: "Pull in all artifacts from mapped requirements in your current assessment into a new one with two clicks.",
            },
          ],
          image: require("../../assets/images/digital-experience/digital-experience-2.png"),
        },
        {
          id: 3,
          title: "Task Management",
          description:
            "Take operational tasks and evidence requests out of email with our Task Management module. This feature, with direct associations to assessment controls, automatically maps the completion of tasks and associated evidence into your compliance frameworks.",
          experienceInner: [
            {
              id: 1,
              icon: "flaticon-check",
              title: "Create, Assign, Edit",
              desc: "Request documentation right from a control in an assessment by creating a Task and assigning it to another user (or yourself). Uploaded artifacts become attachments of that particular control.",
            },
            {
              id: 2,
              icon: "flaticon-check",
              title: "Multiple Configurations",
              desc: "Create a single task or an entire Task Group for a set of tasks that happen in conjunction with eachother. Add frequencies, and the tasks in the group will initiate according to schedule.",
            },
            {
              id: 3,
              icon: "flaticon-check",
              title: '"My To-Do" List and Kanban Board',
              desc: "Track and close tasks assigned to you in My To-Do List, as well as the Active Tasks Table and Active Tasks Board.",
            },
          ],
          image: require("../../assets/images/digital-experience/digital-experience-1.png"),
        },
        {
          id: 4,
          title: "Vulnerability Management",
          description:
            "Manage vulnerabilities, Actions Plans & Milestones (CAPs, POA&Ms, etc.), and view requests from scans all through an easy-to-use set of tabs.",
          experienceInner: [
            {
              id: 1,
              icon: "flaticon-check",
              title: "Scans",
              desc: "Whether you choose one of our integrations or bring your own scan files, we'll parse the data and show you where to focus.",
            },
            {
              id: 2,
              icon: "flaticon-check",
              title: "My Vulnerabilities",
              desc: "Manage any vulnerabilities that are assigned to you.",
            },
            {
              id: 3,
              icon: "flaticon-check",
              title: "Assessment POA&M",
              desc: "View and manage POA&Ms created from assessments.",
            },
          ],
          image: require("../../assets/images/digital-experience/digital-experience-4.png"),
        },
        {
          id: 5,
          title: "Third-Party Risk",
          description:
            "Do more for less - we offer a customizable, easy-to-use interface for conducting, scoring, and evaluating assessments. Vendors and Third-Party Organizations can efficiently and securely provide data related to Information Security, Compliance, and Risk Management controls.",
          experienceInner: [
            {
              id: 1,
              icon: "flaticon-check",
              title: "Auto-Scoring",
              desc: "Using the power of SCF and our auto-scoring technology, reduce time and resources needed to conduct vendor reviews, creating a seamless way for vendors to take assessments.",
            },
            {
              id: 2,
              icon: "flaticon-check",
              title: "Customizable",
              desc: "Utilize a unique, configurable landing page, which can provide instructions for, and even assign tasks to, your TPOs and Vendors.",
            },
            {
              id: 3,
              icon: "flaticon-check",
              title: "Interactive",
              desc: "Secure, seamless communications between your company and the organizations that support your business.",
            },
          ],
          image: require("../../assets/images/digital-experience/digital-experience-5.png"),
        },
        // {
        //   id: 6,
        //   title: "Policy Automator",
        //   description: "Align your policies to the standard(s) of your choice.",
        //   experienceInner: [
        //     {
        //       id: 1,
        //       icon: "flaticon-check",
        //       title: "Super Responsive",
        //       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        //     },
        //     {
        //       id: 2,
        //       icon: "flaticon-check",
        //       title: "High Security",
        //       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        //     },
        //     {
        //       id: 3,
        //       icon: "flaticon-check",
        //       title: "Optimal Choice",
        //       desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        //     },
        //   ],
        //   image: require("../../assets/images/digital-experience/digital-experience-6.png"),
        // },
      ],
    };
  },
  methods: {
    selectTab(id) {
      let tabsList = this.$refs.tabsList;
      this.activeTab = id;
      this.offsetRight = tabsList.clientWidth * (id - 1);
      tabsList.style.right = this.offsetRight + "px";
    },
  },
  mounted() {
    let tabsList = this.$refs.tabsList;
    tabsList.style.right = this.offsetRight + "px";
  },
};
</script>
