<template>
    <div>
        <Navbar />
        <PageBanner />
        <PrivacyPolicyContent />
        <GetOnlyNewUpdate />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageBanner from '../PrivacyPolicy/PageBanner'
import PrivacyPolicyContent from '../PrivacyPolicy/PrivacyPolicyContent'
import GetOnlyNewUpdate from '../Common/GetOnlyNewUpdate'
import Footer from '../Layout/Footer'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        Navbar,
        PageBanner,
        PrivacyPolicyContent,
        GetOnlyNewUpdate,
        Footer,
    }
}
</script>
