<template>
    <div class="industries-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span>We're Everywhere</span>
                <h2>Helping Businesses in All Domains</h2>
                <div class="bar"></div>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-digital-marketing-2"></i>
                        <h3>YOUR INDUSTRY</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-bank"></i>
                        <h3>Banking</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-graduation-cap"></i>
                        <h3>Non-Profits</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-stock-market"></i>
                        <h3>Financial Services</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-employee"></i>
                        <h3>Business Support</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-waiter"></i>
                        <h3>Hospitality</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-stethoscope"></i>
                        <h3>Healthcare</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-industries-box">
                        <i class="flaticon-user"></i>
                        <h3>Assessor Firms</h3>
                        <NuxtLink to="/services-details"></NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HelpingBusinesses',
}
</script>