<template>
  <div>
    <Navbar />
    <MainBanner />
    <Inovation />
    <KeyDifferentiators />
    <MoreBenefits />
    <Necessity />
    <StreetCred />
    <PopularModules />
    <FunFacts />
    <Domains />
    <!-- <Pricing /> -->
    <Footer />
    <!-- <OurImpressivePortfolio /> -->
    <!-- <OurClientsReview /> -->
    <!-- <LatestNews /> -->
    <!-- <Partner /> -->
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import MainBanner from "../Home/MainBanner";
import Inovation from "../Home/Inovation";
import KeyDifferentiators from "../Home/KeyDifferentiators";
import MoreBenefits from "../Home/MoreBenefits";
import Necessity from "../Home/Necessity";
import StreetCred from "../Home/StreetCred";
import PopularModules from "../Home/PopularModules";
import FunFacts from "../Home/FunFacts";
import Domains from "../Home/Domains";
// import Pricing from "../Pricing/PricingDetails";
import Footer from "../Layout/Footer";
// import OurDigitalMarketing from '../HomeOne/OurDigitalMarketing'
// import OurImpressivePortfolio from '../HomeOne/OurImpressivePortfolio'
// import OurClientsReview from '../Common/OurClientsReview'
// import LatestNews from '../Common/LatestNews'
// import Partner from "../Common/Partner";

export default {
  name: "Home",
  components: {
    Navbar,
    MainBanner,
    Inovation,
    KeyDifferentiators,
    MoreBenefits,
    Necessity,
    StreetCred,
    PopularModules,
    FunFacts,
    Domains,
    // Pricing,
    Footer,
    // OurDigitalMarketing,
    // OurImpressivePortfolio,
    // OurClientsReview,
    // LatestNews,
    // Partner,
  },
};
</script>
