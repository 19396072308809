<template>
  <div class="features-area pt-100 pb-70">
    <div class="container">
      <div class="section-title" >
        <span>Innovation</span>
        <h2 style="font-size: 35px;">We're Not So Basic</h2>
        <div class="bar"></div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="single-features">
            <div class="icon">
              <i class="flaticon-promotion"></i>
            </div>
            <h3>
              <router-link to="/services-details">Relevant</router-link>
            </h3>
            <p>
              We support 100+ compliance standards, including CMMC, FEDRAMP,
              NIST, ISO27001, SOC2, PCI DSS, HIPAA.
            </p>

            <!-- <div class="number">
                            <span>1</span>
                        </div> -->

            <!-- <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div> -->
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-features">
            <div class="icon">
              <i class="flaticon-speed"></i>
            </div>
            <h3>
              <router-link to="/services-details">Modular</router-link>
            </h3>
            <p>
              Mature from traditional compliance methods to integrated risk
              management at your own pace and budget.
            </p>
            <!-- 
                        <div class="number">
                            <span>2</span>
                        </div> -->

            <!-- <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div> -->
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-features">
            <div class="icon">
              <i class="flaticon-email"></i>
            </div>
            <h3>
              <router-link to="/services-details">Efficient</router-link>
            </h3>
            <p>
              Information at a glance - real-time access to current-state
              without the frustration of waiting for manual reporting.
            </p>

            <!-- <div class="number">
                            <span>3</span>
                        </div> -->

            <!-- <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div> -->
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="single-features">
            <div class="icon">
              <i class="flaticon-network"></i>
            </div>
            <h3>
              <router-link to="/services-details">Secure</router-link>
            </h3>
            <p>
              Our platform is security-focused, designed to minimize risk while
              maximizing collaboration and efficiency.
            </p>

            <!-- <div class="number">
                            <span>4</span>
                        </div> -->

            <!-- <div class="shape">
                            <img src="../../assets/images/features-shape-1.png" alt="image">
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Innovation",
};
</script>
