<template>
  <div>
    <div class="footer-area pt-100 pb-70">
      <div class="container">
        <div class="row">
          <!-- <div class="d-flex justify-content-center"> -->
          <div class="col-lg-4 offset-lg-4"> 
            <div class="d-flex justify-content-center pb-5">
            <!-- <div class="logo pb-5"> -->
              <router-link to="/">
              <img
              src="../../assets/images/IDL-Logo.png"
              height="80"
              width="350"
              alt="image"
                />
              </router-link>
              <!-- Our success is measured by <b>your</b> Impact. -->
            <!-- </div> -->
             <!-- Come grow with us where it's simply easier, simply better. -->
          </div>
          </div>
          <!-- <div
            class="col-lg-6 offset-lg-3 mt-1 mb-5"
            style="text-align: center"
          >
            Our success is measured by <b>your</b> Impact.
           
          </div> -->
        </div>

        <!-- <div class="row"> -->
          <!-- <div class="col-lg-3 col-sm-3"> -->
            <!-- <div class="single-footer-widget">
            </div> -->

            <!-- 
          <div class="col-lg-3 col-sm-6">
            <div class="single-footer-widget pl-5">
              <h3>Important Links</h3>

              <ul class="quick-links">
                <li>
                  <router-link to="/about">About Us</router-link>
                </li>
                <li>
                  <router-link to="/portfolio-two">Portfolio</router-link>
                </li>
                <li>
                  <router-link to="/services-two">Services</router-link>
                </li>
                <li>
                  <router-link to="/blog-two">Blog</router-link>
                </li>
                <li>
                  <router-link to="/Contact">Contact</router-link>
                </li>
                <li>
                  <router-link to="/privacy-policy">Privacy Policy</router-link>
                </li>
              </ul>
            </div>
          </div> -->
            <!-- <div class="col-12" style="padding: 0px; background-color: rgba(255,0,0,0.1);"> -->
            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="single-footer-widget d-flex justify-content-center">
                  <ul class="footer-contact-info">
                    <li>
                      <i class="flaticon-call"></i>
                      <span>Phone</span>
                      <a href="tel:4043828191">404-382-8191</a>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div class="col-md-4 col-sm-6">
                <div class="single-footer-widget d-flex justify-content-center">
                  <ul class="footer-contact-info">
                    <li>
                      <i class="flaticon-email-1"></i>
                      <span>Email</span>
                      <a href="mailto:team@impactdigitallabs.com">Team@ImpactDigitalLabs.com</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4 col-sm-6">
              <!-- <div class="col-md-4 col-sm-12" style="margin-left: 0px"> -->
                <div class="single-footer-widget d-flex justify-content-center">
                  <ul class="footer-contact-info">
                    <li>
                      <i class="flaticon-pin"></i>
                      <span>Location</span>
                      <a
                        href="https://www.google.com/maps/@51.5287718,-0.2416804,11z"
                        target="_blank"
                        >Atlanta, GA</a
                      >
                    </li>
                  </ul>
                </div>
            </div>

          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="copyright-area">
      <div class="container">
        <div class="copyright-area-content">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <p>
                &copy; {{ currentYear }} Impact Digital Labs, Inc. All Rights
                Reserved.
                <a href="https://envytheme.com/" target="_blank"> </a>
              </p>
            </div>

            <div class="col-lg-6 col-md-6">
              <ul>
                <!-- <li>
                  <router-link to="/terms-of-service"
                    >Terms of Service</router-link
                  >
                </li> -->
                <li>
                  <router-link to="/privacy-policy">Privacy Policy</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
