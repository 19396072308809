<template>
  <div
    class="testimonial-area testimonial-two"
    style="padding-left: 30px; background-color: rgba(255, 0, 0, 0.1)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="section-title pt-5">
            <span>Testimonials</span>
            <h2 style="font-size: 35px;">Yes Please!</h2>  
            <div class="bar"></div>
          </div>
        </div>
      </div>

      <div class="testimonial-slider">
        <carousel
          :autoplay="5000"
          :settings="settings"
          :wrap-around="true"
          :breakpoints="breakpoints"
        >
          <slide v-for="slide in carouselItems" :key="slide.id">
            <div class="testimonial-item">
              <div class="row">
                <div>
                  <div :class="slide.class"></div>
                </div>

                <div class="col-lg-8 offset-lg-2">
                  <div class="testimonial-inner-content">
                    <!-- <div class="icon">
                      <i :class="slide.icon"></i>
                    </div> -->
                    <p>{{ slide.desc }}</p>
                    <!-- <div class="icon justtify-content-right">
                      <i :class="slide.icon"></i>
                    </div> -->
                    <div class="info-text">
                      <h4>{{ slide.name }}</h4>
                      <span>{{ slide.position }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>

          <template #addons>
            <Navigation />
          </template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "OurClientsReview",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    carouselItems: [
      {
        id: 1,
        // class: "testimonial-image",
        icon: "flaticon-left-quotes-sign",
        desc: "The Cask Compliance Assessment Tool (CAT) is powered by Impact’s IRM software platform.  The platform was extremely useful in helping Cask Government Services prepare for and successfully become the 3rd Authorized CP3AO. Impact IRM enabled CASK to integrate existing CMMC workflows, organize and immediately access a range of data and automate CMMC compliance tasks. We are looking forward to a terrific partnership!",
        name: "Stacy High-Brinkley, Chief Information Security Officer",
        position: "Cask Government Services",
      },
      // {
      //   id: 2,
      //   // class: "testimonial-image image-two",
      //   icon: "flaticon-left-quotes-sign",
      //   desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.",
      //   name: "John Verry",
      //   position: "Pivot Point Security",
      // },
      {
        id: 3,
        // class: "testimonial-image image-three",
        icon: "flaticon-left-quotes-sign",
        desc: "Coalfire Federal chose Impact IRM after a rigorous selection process because it simplifies and enforces a consistent, standards-based assessment methodology. Impact IRM provides comprehensive status reporting and delivers actionable information that allows for the assignment of tasks and mitigation actions. Impact IRM will enable us to efficiently deliver consistent, high-quality services to our clients.",
        name: "Stuart Itkin, Vice President CMMC and FedRAMP Assurance",
        position: "Coalfire Federal",
      },
    ],
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
  }),
});
</script>
