<template>
  <div class="pricing-area pb-50">
    <div class="container">
      <div class="section-title pt-100">
        <span>Pricing Plans</span>
        <h2>A Powerful, Affordable Ally</h2>
        <div class="bar"></div>
      </div>

      <div class="pricing-tab">
        <div class="tabs">
          <ul class="tabs__header">
            <li
              class="tabs__header-item"
              v-for="tab in tabs"
              v-on:click="selectTab(tab.id)"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              {{ tab.title }}
            </li>
          </ul>
        </div>
        <div class="tabs__container">
          <div class="tabs__list" ref="tabsList">
            <div
              class="tabs__list-tab"
              v-for="tab in tabs"
              v-bind:class="{ active: activeTab == tab.id }"
              :key="tab.id"
            >
              <div class="row">
                <div
                  class="col-lg-4 col-md-6"
                  v-for="pricing in tab.singlePricing"
                  :key="pricing.id"
                >
                  <div :class="pricing.class">
                    <div class="pricing-header">
                      <h3>{{ pricing.title }}</h3>
                    </div>

                    <div class="price">${{ pricing.price }}</div>

                    <ul class="pricing-features">
                      <li
                        v-for="features in pricing.pricingFeatures"
                        :key="features.id"
                      >
                        <i :class="features.icon"></i>
                        {{ features.feature }}
                      </li>
                    </ul>

                    <div class="pricing-btn">
                      <router-link :to="pricing.btnLink" class="default-btn">
                        {{ pricing.btnText }}
                      </router-link>
                    </div>

                    <div class="pricing-shape">
                      <img
                        src="../../assets/images/pricing-shape.png"
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingDetails",
  data() {
    return {
      activeTab: 1,
      offsetRight: 0,

      tabs: [
        {
          id: 1,
          title: "Monthly",
          singlePricing: [
            {
              id: 1,
              class: "single-pricing-box top-1",
              title: "Basic Plan",
              price: "24",
              btnText: "Get Started",
              btnLink: "/contact-one",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "flaticon-check-mark",
                  feature: "SEO Audits",
                },
                {
                  id: 2,
                  icon: "flaticon-check-mark",
                  feature: "SEO Management",
                },
                {
                  id: 3,
                  icon: "flaticon-check-mark",
                  feature: "SEO Copywriting",
                },
                {
                  id: 4,
                  icon: "flaticon-check-mark",
                  feature: "Link Building",
                },
                {
                  id: 5,
                  icon: "flaticon-check-mark",
                  feature: "Site Migration",
                },
                {
                  id: 6,
                  icon: "flaticon-check-mark",
                  feature: "Video Camplaigns",
                },
                {
                  id: 7,
                  icon: "flaticon-check-mark",
                  feature: "Unlimited SEO Keywords",
                },
              ],
            },
            {
              id: 2,
              class: "single-pricing-box",
              title: "Standard Plan",
              price: "59",
              btnText: "Get Started",
              btnLink: "/contact-one",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "flaticon-check-mark",
                  feature: "SEO Audits",
                },
                {
                  id: 2,
                  icon: "flaticon-check-mark",
                  feature: "SEO Management",
                },
                {
                  id: 3,
                  icon: "flaticon-check-mark",
                  feature: "SEO Copywriting",
                },
                {
                  id: 4,
                  icon: "flaticon-check-mark",
                  feature: "Link Building",
                },
                {
                  id: 5,
                  icon: "flaticon-check-mark",
                  feature: "Site Migration",
                },
                {
                  id: 6,
                  icon: "flaticon-check-mark",
                  feature: "Video Camplaigns",
                },
                {
                  id: 7,
                  icon: "flaticon-check-mark",
                  feature: "Unlimited SEO Keywords",
                },
              ],
            },
            {
              id: 3,
              class: "single-pricing-box top-2",
              title: "Premium Plan",
              price: "89",
              btnText: "Get Started",
              btnLink: "/contact-one",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "flaticon-check-mark",
                  feature: "SEO Audits",
                },
                {
                  id: 2,
                  icon: "flaticon-check-mark",
                  feature: "SEO Management",
                },
                {
                  id: 3,
                  icon: "flaticon-check-mark",
                  feature: "SEO Copywriting",
                },
                {
                  id: 4,
                  icon: "flaticon-check-mark",
                  feature: "Link Building",
                },
                {
                  id: 5,
                  icon: "flaticon-check-mark",
                  feature: "Site Migration",
                },
                {
                  id: 6,
                  icon: "flaticon-check-mark",
                  feature: "Video Camplaigns",
                },
                {
                  id: 7,
                  icon: "flaticon-check-mark",
                  feature: "Unlimited SEO Keywords",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: "Yearly",
          singlePricing: [
            {
              id: 1,
              class: "single-pricing-box top-1",
              title: "Basic Plan",
              price: "224",
              btnText: "Get Started",
              btnLink: "/contact-one",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "flaticon-check-mark",
                  feature: "SEO Audits",
                },
                {
                  id: 2,
                  icon: "flaticon-check-mark",
                  feature: "SEO Management",
                },
                {
                  id: 3,
                  icon: "flaticon-check-mark",
                  feature: "SEO Copywriting",
                },
                {
                  id: 4,
                  icon: "flaticon-check-mark",
                  feature: "Link Building",
                },
                {
                  id: 5,
                  icon: "flaticon-check-mark",
                  feature: "Site Migration",
                },
                {
                  id: 6,
                  icon: "flaticon-check-mark",
                  feature: "Video Camplaigns",
                },
                {
                  id: 7,
                  icon: "flaticon-check-mark",
                  feature: "Unlimited SEO Keywords",
                },
              ],
            },
            {
              id: 2,
              class: "single-pricing-box",
              title: "Standard Plan",
              price: "259",
              btnText: "Get Started",
              btnLink: "/contact-one",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "flaticon-check-mark",
                  feature: "SEO Audits",
                },
                {
                  id: 2,
                  icon: "flaticon-check-mark",
                  feature: "SEO Management",
                },
                {
                  id: 3,
                  icon: "flaticon-check-mark",
                  feature: "SEO Copywriting",
                },
                {
                  id: 4,
                  icon: "flaticon-check-mark",
                  feature: "Link Building",
                },
                {
                  id: 5,
                  icon: "flaticon-check-mark",
                  feature: "Site Migration",
                },
                {
                  id: 6,
                  icon: "flaticon-check-mark",
                  feature: "Video Camplaigns",
                },
                {
                  id: 7,
                  icon: "flaticon-check-mark",
                  feature: "Unlimited SEO Keywords",
                },
              ],
            },
            {
              id: 3,
              class: "single-pricing-box top-2",
              title: "Premium Plan",
              price: "289",
              btnText: "Get Started",
              btnLink: "/contact-one",
              pricingFeatures: [
                {
                  id: 1,
                  icon: "flaticon-check-mark",
                  feature: "SEO Audits",
                },
                {
                  id: 2,
                  icon: "flaticon-check-mark",
                  feature: "SEO Management",
                },
                {
                  id: 3,
                  icon: "flaticon-check-mark",
                  feature: "SEO Copywriting",
                },
                {
                  id: 4,
                  icon: "flaticon-check-mark",
                  feature: "Link Building",
                },
                {
                  id: 5,
                  icon: "flaticon-check-mark",
                  feature: "Site Migration",
                },
                {
                  id: 6,
                  icon: "flaticon-check-mark",
                  feature: "Video Camplaigns",
                },
                {
                  id: 7,
                  icon: "flaticon-check-mark",
                  feature: "Unlimited SEO Keywords",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    selectTab(id) {
      let tabsList = this.$refs.tabsList;
      this.activeTab = id;
      this.offsetRight = tabsList.clientWidth * (id - 1);
      tabsList.style.right = this.offsetRight + "px";
    },
  },
  mounted() {
    let tabsList = this.$refs.tabsList;
    tabsList.style.right = this.offsetRight + "px";
  },
};
</script>
