<template>
  <div class="terms-of-service-area ptb-100">
    <div class="container">
      <div class="terms-of-service-content">
        <h5 style="margin: 0in; background: white">
          <strong
            ><u
              ><span style="font-family: 'Segoe UI', sans-serif; color: #373737"
                >TERMS OF SERVICE<o:p></o:p></span></u
          ></strong>
        </h5>

        <h5 style="margin: 0in; background: white">
          <span style="font-family: 'Segoe UI', sans-serif; color: #373737"
            ><o:p>&nbsp;</o:p></span
          >
        </h5>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >1.ACCEPTANCE OF TERMS.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >1.1
            <strong
              ><span style="font-family: Helvetica"
                >Impact Digital Labs, Inc</span
              ></strong
            >
            (herein referred to as the “Company”, “we”, “us” or “our”) provides
            its Service (as defined below) to you through its web site(s)
            located at:
            <strong
              ><span style="font-family: Helvetica"
                >https://Impact-Labs.io</span
              ></strong
            >, and/or other web sites facilitating access to the Company’s
            Service (as defined below) (each together with its subdomains, a
            “Site”), subject to this Terms of Service agreement (the “TOS”). By
            accepting this TOS or by accessing or using the Service, you
            acknowledge that you have read, understood, and agree to be bound by
            this TOS. If you are entering into this TOS on behalf of a company,
            business or other legal entity, you represent that you have the
            authority to bind such entity and its affiliates to this TOS, in
            which case the terms “you” or “<span class="GramE">your</span>”
            shall refer to such entity and its affiliates. If you do not have
            such authority, or if you do not agree with this TOS, you must not
            accept this TOS and may not use the Service. For the avoidance of
            doubt, in the event of conflict between this TOS, and any other
            agreement between you and the Company, this TOS shall govern your
            use of the Service, and shall supersede
            <span class="GramE">any and all</span> other terms that purport to
            govern such Service.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >1.2 THIS TOS CONTAINS AN ARBITRATION AGREEMENT, WHICH WILL, WITH
            LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST
            COMPANY TO BINDING AND FINAL ARBITRATION. UNDER THE ARBITRATION
            AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST
            COMPANY ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER
            IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU
            WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY,
            INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.<o:p
            ></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >1.3 Company may change this TOS at any time in its sole discretion.
            Notwithstanding the foregoing, Company may, at its sole discretion
            provide notice of such changes either by emailing the email address
            associated with your Account or by posting a notice on the Site. You
            can review the most current version of this TOS at any time at:
            <strong
              ><span style="font-family: Helvetica"
                >https://Impact-Labs.io.</span
              ></strong
            >
            If any change to this TOS is not acceptable to you, your only remedy
            is stop using the Service.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >1.4 Company will facilitate the issuance of unique
            <span class="GramE">user names</span> and passwords for account
            creation purposes. Each individual accessing the Service will be
            issued an account by which they may access the Site and Content
            (each, an “Account”), and must maintain their own unique
            <span class="GramE">user name</span> and password in connection with
            their Account. Under no circumstances may two or more individuals
            access the Site and/or Content using the same Account. Company may
            at any time limit the total number of accounts and users at its sole
            discretion.&nbsp;<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >2. DESCRIPTION OF SERVICE.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >The “Service” includes (a) the Site, (b) one or more of Company’s
            services made available through the Site, including but not limited
            to its support center, and training resources, as applicable, and
            (c) all software, data, reports, text, images, sounds, video, and
            content made available through any of the foregoing (collectively
            referred to as the “Content”). Any new features added to or
            augmenting the Service are also subject to this TOS. Please visit
            our Privacy Policy to understand how Company collects and uses
            personal information in connection with the Service.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >3. GENERAL CONDITIONS / ACCESS AND USE OF THE SERVICE.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.1 Subject to the terms and conditions of this TOS, you may access
            and use the Service only for lawful purposes. All rights,
            <span class="GramE">title</span> and interest in and to the Service
            and its components will remain with and belong exclusively to
            Company. You shall not (a) sublicense, resell, rent, lease,
            transfer, assign, time share or otherwise commercially exploit or
            make the Service available to any third party; (b) use the Service
            in any unlawful manner (including without limitation in violation of
            any data, privacy or export control laws) or in any manner that
            interferes with or disrupts the integrity or performance of the
            Service or its components, or (c) modify, adapt or hack the Service
            to, or otherwise attempt to gain unauthorized access to the Service
            or its related systems or networks. You shall comply with any codes
            of conduct, policies or other notices Company provides you or
            publishes in connection with the Service, and you shall promptly
            notify Company if you learn of a security breach related to the
            Service. Without limiting the foregoing, you shall comply with our
            Acceptable Use Policy.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.2 You agree not to access the Service by any means other than
            through the interface that is provided by Company for use in
            accessing the Service. Any rights not expressly granted herein are
            reserved and no license or right to use any trademark of Company or
            any third party is granted to you in connection with the Service.
            The Company trademarks used and displayed on the Service are
            registered and unregistered trademarks or service marks of the
            Company. Other company, product, and service names located on the
            Service may be trademarks or service marks owned by
            <span class="GramE">third-parties</span> (the “Third-Party
            Trademarks”, and, collectively with the Company trademarks, the
            “Trademarks”). Nothing on the Service or in these TOS should be
            construed as granting, by implication, estoppel, or otherwise, any
            license or right to use any Trademark displayed on the Service
            without the prior written consent of the Company or third-party
            trademark owner, as applicable, specific for each such use. The
            Trademarks may not be used to disparage the Company or the
            applicable third-party, the Company’s or third-party’s products or
            services, or in any manner (using commercially reasonable judgment)
            that may damage any goodwill in the Trademarks. Use of any
            Trademarks as part of a link to or from any web site is prohibited
            without the Company’s prior written consent. All goodwill generated
            from the use of any Company Trademark shall inure to the Company’s
            benefit.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.3 You are solely responsible for all data, information, feedback,
            suggestions, text, <span class="GramE">content</span> and other
            materials that you upload, post, deliver, provide or otherwise
            transmit or store (hereafter “post(<span class="SpellE">ing</span
            >)”) in connection with or relating to the Service (“Your Content”).
            You agree Your Content shall not include anything that (a) is
            unlawful, libelous, defamatory, obscene, pornographic, indecent,
            lewd, suggestive, harassing, threatening, invasive of privacy or
            publicity rights, abusive, inflammatory, fraudulent or otherwise
            objectionable; (b) would constitute, encourage or provide
            instructions for a criminal offense, violate the rights of any
            party, or that would otherwise create liability or violate any
            local, state, national or international law; (iii) may infringe any
            patent, trademark, trade secret, copyright or other intellectual or
            proprietary right of any party; (iv) constitutes unsolicited
            promotions, political campaigning, advertising, contests, raffles,
            or solicitations; (v) constitutes the private information of any
            third party; or (vi) contains links to third party websites
            (collectively, “Prohibited Content”). Without limiting the
            foregoing, you shall comply with our Acceptable Use Policy.<o:p
            ></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.4 The Service may contain links to third party web sites
            (“External Sites”). These links are provided solely as a convenience
            to you and not as an endorsement by us of the content on such
            External Sites. The content of such External Sites is developed and
            provided by others. You should contact the site administrator or
            webmaster for those External Sites if you have any concerns
            regarding such links or any content located on such External Sites.
            We are not responsible for the content of any linked External Sites
            and do not make any representations regarding the content or
            accuracy of any materials on such External Sites. You should take
            precautions when downloading files from all web sites to protect
            your computer from viruses and other destructive programs. If you
            decide to access any External Sites, you do so at your own risk.<o:p
            ></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.5 You are solely responsible for maintaining the confidentiality
            of your login, <span class="GramE">password</span> and Account and
            for all access and other activities that occur under your login or
            Account. Company reserves the right to access your Account
            <span class="GramE">in order to</span> respond to your requests for
            technical support. By posting Your Content on or through the
            Service, you hereby do and shall grant Company a worldwide,
            non-exclusive, perpetual, irrevocable, royalty-free, fully paid,
            sub-licensable and transferable license to use, modify, reproduce,
            distribute, display, publish and perform Your Content in connection
            with the Service. Company has the right, but not the obligation, to
            monitor the Service, Content, or Your Content. You further agree
            that Company may remove or disable any of Your Content at any time
            for any reason (including, but not limited to, upon receipt of
            claims or allegations from third parties or authorities relating to
            such Content), or for no reason at all. You understand that Company
            does not endorse or approve any content on the Service provided by
            any third party.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.6 You understand that the operation of the Service, including
            Your Content, may be unencrypted and involve (a) transmissions over
            various networks; (b) changes to conform and adapt to technical
            requirements of connecting networks or devices and (c) transmission
            to Company’s <span class="GramE">third party</span> vendors and
            hosting partners to provide the necessary hardware, software,
            networking, storage, and related technology required to operate and
            maintain the Service. Accordingly, you acknowledge that you bear
            sole responsibility for adequate security,
            <span class="GramE">protection</span>
            and backup of Your Content. Company will have no liability to you
            for any unauthorized access or use of any of Your Content, or any
            corruption, deletion,
            <span class="GramE">destruction</span> or loss of any of Your
            Content.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.7 You shall be responsible for obtaining and maintaining any
            equipment and ancillary services needed to connect to, access or
            otherwise use the Service, including, without limitation, modems,
            hardware, server, software, operating system, networking, web
            servers, long <span class="GramE">distance</span> and local
            telephone service (collectively, “Equipment”). You shall be
            responsible for ensuring that such Equipment is compatible with the
            Service and complies with all configurations and specifications set
            forth in Company’s published policies and other documentation then
            in effect. You shall also be responsible for maintaining the
            security of the Equipment, your Account,
            <span class="GramE">passwords</span> and files, and for all uses of
            your Account or the Equipment with or without your knowledge or
            consent.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.8 The failure of Company to exercise or enforce any right or
            provision of this TOS shall not be a waiver of that right. You
            acknowledge that this TOS is a contract between you and Company,
            even though it is electronic and is not physically signed by you and
            Company, and it governs your use of the Service.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >3.9 Subject to the terms hereof, Company may (but has no obligation
            to) provide technical support services, through email in accordance
            with our standard practice.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >4. REPRESENTATIONS AND WARRANTIES.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >You represent and warrant to Company that (<span class="SpellE"
              >i</span
            >) you have full power and authority to enter into this TOS; (ii)
            you own all Your Content or have obtained all permissions, releases,
            rights or licenses required to engage in your posting and other
            activities (and allow Company to perform its obligations) in
            connection with the Service without obtaining any further releases
            or consents; (iii) your use of the Service, and Company’s exercise
            of all rights and license granted by you herein, do not and will not
            violate, infringe, or misappropriate any third party’s copyright,
            trademark, right of privacy or publicity, or other personal or
            proprietary right, nor does Your Content contain any Prohibited
            Content; and (iv) you are eighteen (18) years of age or older.<o:p
            ></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >5. TERMINATION.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >You have the right to terminate your Account at any time by sending
            a cancellation request to<strong
              ><span style="font-family: Helvetica"
                >&nbsp;Team@Impact-Labs.io</span
              ></strong
            >
            No such cancellation request shall any effect on any other agreement
            <span class="GramE">entered into</span> between you and Company.
            Subject to earlier termination as provided below, Company may
            terminate your Account and this TOS at any time in its sole
            discretion, including if you violate our Acceptable Use Policy or if
            the use of your Account compromises the confidentiality, integrity,
            accuracy, or security of any system, network, data or any other
            property of any person or entity, including ours. In addition to any
            other remedies we may have, Company may also terminate this TOS upon
            thirty (30) days’ notice (or ten (10) days in the case of
            nonpayment), if you breach any of the terms or conditions of this
            TOS. Company reserves the right to modify or discontinue,
            temporarily or permanently, the Service (or any part thereof).
            <span class="GramE">All of</span> Your Content on the Service (if
            any) may be permanently deleted by Company upon any termination of
            your Account in its sole discretion.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >6. DISCLAIMER OF WARRANTIES.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >The Service may be temporarily unavailable for scheduled
            maintenance or for unscheduled emergency maintenance, either by
            Company or by third-party providers, or because of other causes
            beyond our reasonable control. HOWEVER, THE SERVICE, INCLUDING THE
            SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED
            ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY
            KIND, AND COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
            IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT
            COMPANY DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED,
            TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE, NOR DOES IT MAKE ANY
            WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
            SERVICE, AND NO INFORMATION, ADVICE OR SERVICE OBTAINED BY YOU FROM
            COMPANY OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT
            EXPRESSLY STATED IN THIS TOS. THE SERVICE IS CONTINUOUSLY BEING
            DEVELOPED AND IMPROVED AND IS EXPECTED TO CONTAIN DEFECTS. YOU ARE
            ADVISED TO SAFEGUARD IMPORTANT DATA, TO USE CAUTION AND NOT TO RELY
            IN ANY WAY ON THE SERVICE, INCLUDING BUT NOT LIMITED TO THE CORRECT
            FUNCTIONING OR PERFORMANCE OF THE SERVICE. THESE TERMS SHALL NOT
            CREATE ANY OBLIGATION FOR COMPANY TO CONTINUE TO DEVELOP,
            PRODUCTIZE, SUPPORT, REPAIR, OFFER FOR SALE OR IN ANY OTHER WAY
            CONTINUE TO PROVIDE OR DEVELOP THE SERVICE FOR YOU OR ANY OTHER
            PARTY. USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO ANY DATA
            INPUT INTO THE SERVICE, BY WILL BE AT YOUR OWN RISK, AND COMPANY
            WILL BEAR NO RISK OR LIABILITY ARISING FROM THE SAME. YOU SHALL BEAR
            THE ENTIRE RISK AS TO THE QUALITY AND THE PERFORMANCE OF SERVICE.
            COMPANY PROVIDES NO ASSURANCE THAT ANY SPECIFIC ERRORS OR
            DISCREPANCIES IN THE SERVICE WILL BE CORRECTED. COMPANY IS NOT
            RESPONSIBLE FOR ANY CONTENT MADE AVAILABLE ON OR IN CONNECTION WITH
            THE SERVICE BY ANY THIRD PARTY, INCLUDING BUT NOT LIMITED TO ANY
            POSTS MADE BY ANY OTHER USERS OF THE SERVICE, NOR FOR ANY THIRD
            PARTY WEB SITES OR RESOURCES LINKED TO OR OTHERWISE MADE AVAILABLE
            VIA THE SERVICE, AND YOU ACKNOWLEDGE THAT ANY RELIANCE ON SUCH THIRD
            PARTY WEB SITES OR RESOURCES LINKED TO OR OTHERWISE MADE AVAILABLE
            VIA THE SERVICE WILL BE AT YOUR OWN RISK. Company reserves the right
            at any time unilaterally to abandon the Service or, if it does
            include the Service as part of a commercially released product, to
            alter features, licensing terms, or other characteristics of the
            commercial release. Some jurisdictions do not allow the limitation
            or exclusion of implied warranties or how long an implied warranty
            may last, so the above limitations may not apply to you.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >7. LIMITATION OF LIABILITY.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >7.1 UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
            CONTRACT, TORT, OR OTHERWISE) SHALL COMPANY BE LIABLE TO YOU OR ANY
            THIRD PARTY FOR (A) ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY,
            CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST
            SALES OR BUSINESS, LOST DATA, OR (B) FOR ANY DIRECT DAMAGES, COSTS,
            <span class="GramE">LOSSES</span> OR LIABILITIES IN EXCESS ONE
            HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION ALLOCATE
            THE RISKS UNDER THIS TOS BETWEEN THE PARTIES, AND THE PARTIES HAVE
            RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO
            THIS TOS.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >7.2 Some states do not allow the exclusion of implied warranties or
            limitation of liability for incidental or consequential damages,
            which means that some of the above limitations may not apply to you.
            IN THESE STATES, COMPANY’S LIABILITY WILL BE LIMITED TO THE GREATEST
            EXTENT PERMITTED BY LAW.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >8. INDEMNIFICATION.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >You shall defend, indemnify, and hold harmless Company from and
            against any claims, actions or demands, including without limitation
            reasonable legal and accounting fees, arising or resulting from your
            breach of this TOS, any of Your Content, or your other access,
            contribution <span class="GramE">to,</span> use or misuse of the
            Service. Company shall provide notice to you of any such claim,
            <span class="GramE">suit</span> or demand. Company reserves the
            right to assume the exclusive defense and control of any matter
            which is subject to indemnification under this section. In such
            case, you agree to cooperate with any reasonable requests assisting
            Company’s defense of such matter.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >9. ARBITRATION.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >9.1 Agreement to Arbitrate: This Section is referred to as the
            “Arbitration Agreement”. You agree that
            <span class="GramE">any and all</span> disputes or claims that have
            arisen or may arise between you and Company, whether arising out of
            or relating to this Agreement or the Service, shall be resolved
            exclusively through final and binding arbitration, rather than a
            court, in accordance with the terms of this Arbitration Agreement,
            except that you may assert individual claims in small claims court,
            if your claims qualify. You agree that, by agreeing to this
            Agreement, you and Company are each waiving the right to a trial by
            jury or to participate in a class action. Your rights will be
            determined by a neutral arbitrator, not a judge or jury. The Federal
            Arbitration Act governs the interpretation and enforcement of this
            Arbitration Agreement. Notwithstanding the foregoing, this
            Arbitration Agreement shall not preclude either party from pursuing
            a court action for the sole purpose of obtaining a temporary
            restraining order or preliminary injunction in circumstances in
            which such relief is
            <span class="GramE">appropriate;</span> provided that any other
            relief shall be pursued through an arbitration proceeding pursuant
            to this Arbitration Agreement.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >9.2 Prohibition of Class and Representative Actions and
            Non-Individualized Relief: You and Company agree that each may bring
            claims against the other only on an individual basis and not as
            plaintiff or class member in any purported class or representative
            action or proceeding. Unless both you and Company agree otherwise,
            the arbitrator may not consolidate or join more than one person’s or
            party’s claims and may not otherwise preside over any form of a
            consolidated, representative, or class proceeding. Also, the
            arbitrator may award relief (including monetary, injunctive, and
            declaratory relief) only in favor of the individual party seeking
            relief and only to the extent necessary to provide relief
            necessitated by that party’s individual claim(s).<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal">
          <span style="font-family: Helvetica; color: #343a40"
            >9.3 Pre-Arbitration Dispute Resolution: Company is always
            interested in resolving disputes amicably and efficiently, and most
            participant concerns can be resolved quickly and to the
            participant’s satisfaction by emailing Company’s support team
            at&nbsp;<strong
              ><span style="font-family: Helvetica"
                >Team@Impact-Labs.io.com</span
              ></strong
            >. If such efforts prove unsuccessful, a party who intends to seek
            arbitration must first send to the other, by certified mail, a
            written Notice of Dispute (“Notice”). The Notice to Company should
            be sent to
            <strong
              ><span style="font-family: Helvetica"
                >Team@Impact-Labs.io</span
              ></strong
            >
            (“Notice Address”). The Notice must (<span class="SpellE">i</span>)
            describe the nature and basis of the claim or dispute and (ii) set
            forth the specific relief sought. If Company and you do not resolve
            the claim within sixty (60) calendar days after the Notice is
            received, you or Company may commence an arbitration proceeding.
            During the arbitration, the amount of any settlement offer made by
            <span class="GramE">Company</span> or you shall not be disclosed to
            the arbitrator until after the arbitrator determines the amount, if
            any, to which you or Company is entitled.</span
          >
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >9.4 Arbitration Procedures: Arbitration will be conducted by a
            neutral arbitrator in accordance with the American Arbitration
            Association’s (“AAA”) rules and procedures, including the AAA’s
            Commercial Arbitration Rules (collectively, the “AAA Rules”), as
            modified by this Arbitration Agreement. If there is any
            inconsistency between any term of the AAA Rules and any term of this
            Arbitration Agreement, the applicable terms of this Arbitration
            Agreement will control unless the arbitrator determines that the
            application of the inconsistent Arbitration Agreement terms would
            not result in a fundamentally fair arbitration. All issues are for
            the arbitrator to decide, including, but not limited to, issues
            relating to the scope, enforceability, and arbitrability of this
            Arbitration Agreement. The arbitrator can award the same damages and
            relief on an individual basis that a court can award to an
            individual under this Agreement and applicable law. Decisions by the
            arbitrator are enforceable in court and may be overturned by a court
            only for very limited reasons. Unless Company and you agree
            otherwise, any arbitration hearings will take place in a reasonably
            convenient location for both parties with due consideration of their
            ability to travel and other pertinent circumstances. If the parties
            are unable to agree on a location, the determination shall be made
            by AAA. If your claim is for $10,000 or less, Company agrees that
            you may choose whether the arbitration will be conducted solely
            <span class="GramE">on the basis of</span> documents submitted to
            the arbitrator, through a telephonic hearing or by an in-person
            hearing as established by the AAA Rules. If your claim exceeds
            $10,000, the right to a hearing will be determined by the AAA Rules.
            Regardless of the <span class="GramE">manner in which</span> the
            arbitration is conducted, the arbitrator shall issue a reasoned
            written decision sufficient to explain the essential findings and
            conclusions on which the award is based.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >9.5 Costs of Arbitration: Payment of all filing, administration,
            and arbitrator fees (collectively, the “Arbitration Fees”) will be
            governed by the AAA Rules, unless otherwise provided in this
            Arbitration Agreement. Any payment of attorneys’ fees will be
            governed by the AAA Rules.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >9.6 Confidentiality: All aspects of the arbitration proceeding, and
            any ruling, decision, or award by the arbitrator, will be strictly
            confidential for the benefit of all parties.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >9.7 Severability: If a court or the arbitrator decides that any
            term or provision of this Arbitration Agreement other than clause
            (b) above is invalid or unenforceable, the parties agree to replace
            such term or provision with a term or provision that is valid and
            enforceable and that comes closest to expressing the intention of
            the invalid or unenforceable term or provision, and this Arbitration
            Agreement shall be enforceable as so modified. If a court or the
            arbitrator decides that any of the provisions of clause (b) is
            invalid or unenforceable, then the entirety of this Arbitration
            Agreement shall be null and void. The remainder of this Agreement
            will continue to apply.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >10. ASSIGNMENT.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >You may not assign this TOS without the prior written consent of
            Company, but Company may assign or transfer this TOS, in whole or in
            part, without restriction.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >11. MISCELLANEOUS.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >The Privacy Policy and the Acceptable Use Policy are part of this
            TOS and incorporated herein as if they were written here in full. If
            any provision of this TOS is found to be unenforceable or invalid,
            that provision will be limited or eliminated to the minimum extent
            necessary so that this TOS will otherwise remain in full force and
            effect and enforceable. Both parties agree that this TOS is the
            complete and exclusive statement of the mutual understanding of the
            parties and supersedes and cancels all previous written and oral
            agreements, communications and other understandings relating to the
            subject matter of this TOS, and that all waivers and modifications
            must be in a writing signed by both parties, except as otherwise
            provided herein. No agency, partnership, joint venture, or
            employment is created as a result of this
            <span class="GramE">TOS</span> and you do not have any authority of
            any kind to bind Company in any respect whatsoever. In any action or
            proceeding to enforce rights under this TOS, the prevailing party
            will be entitled to recover costs and attorneys’ fees. All notices
            under this TOS will be in writing and will be deemed to have been
            duly given when received, if personally delivered; when receipt is
            electronically confirmed, if transmitted by facsimile or email; the
            day after it is sent, if sent for next day delivery by recognized
            overnight delivery service; and upon receipt, if sent by certified
            or registered mail, return receipt requested.<o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            ><o:p>&nbsp;</o:p></span
          >
        </p>

        <p style="margin: 0in; background: white">
          <strong
            ><span style="font-family: Helvetica; color: #343a40"
              >12. GOVERNING LAW.</span
            ></strong
          ><span style="font-family: Helvetica; color: #343a40"
            ><o:p></o:p
          ></span>
        </p>

        <p style="margin: 0in; background: white">
          <span style="font-family: Helvetica; color: #343a40"
            >This TOS shall be governed by the laws of the State of Georgia
            without regard to the principles of conflicts of law. In the event
            any dispute is not arbitrated, you hereby expressly agree to submit
            to the exclusive personal jurisdiction of the federal and state
            courts of the State of Virginia for the purpose of resolving any
            dispute relating to your access to or use of the Service.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal"><o:p>&nbsp;</o:p></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfServiceContent",
};
</script>
