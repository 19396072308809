<template>
  <div class="fun-facts-area pb-70">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-fun-fact-box">
            <div class="icon">
              <i class="flaticon-checked"></i>
            </div>

            <h2>Yes!</h2>
            <p>Small Business Users</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-fun-fact-box">
            <div class="icon">
              <i class="flaticon-happy"></i>
            </div>

            <h2>Yes!</h2>
            <p>Fortune 100 Users</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-fun-fact-box">
            <div class="icon">
              <i class="flaticon-technical-support"></i>
            </div>

            <h2>Yep!</h2>
            <p>Full-Time, US-based Developers</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-fun-fact-box">
            <div class="icon">
              <i class="flaticon-trophy"></i>
            </div>

            <h2>Still Yes!</h2>
            <p>Free Training & Support</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FunFacts",
};
</script>
