import { createWebHistory, createRouter } from "vue-router";

// Pages below are in use and migrated to new naming convention
import Home from "../components/Pages/Home";
import Overview from "../components/Pages/Overview";
import Assessments from "../components/Pages/Assessments";
import CrosswalkDashboard from "../components/Pages/CrosswalkDashboard";
import VulnerabilityManagement from "../components/Pages/VulnerabilityManagement";
import TaskManagement from "../components/Pages/TaskManagement";
import ThirdPartyRisk from "../components/Pages/ThirdPartyRisk";
import PolicyAutomator from "../components/Pages/PolicyAutomator";
import EvidenceManagement from "../components/Pages/EvidenceManagement";
import About from "../components/Pages/About";
import Pricing from "../components/Pages/Pricing";
import Portfolio from "../components/Pages/Portfolio";
import FaqPage from "../components/Pages/FaqPage";
import Contact from "../components/Pages/Contact";

//Pages below have not been updated yet

// import HomePageOne from "../components/Pages/HomePageOne";
// import HomePageTwo from "../components/Pages/HomePageTwo";
// import HomePageThree from "../components/Pages/HomePageThree";
// import HomePageFour from "../components/Pages/HomePageFour";
// import HomePageFive from "../components/Pages/HomePageFive";
// import AboutPageOne from "../components/Pages/AboutPageOne";
// import TeamPageOne from "../components/Pages/TeamPageOne";
// import TeamPageTwo from "../components/Pages/TeamPageTwo";
// import PricingPageTwo from "../components/Pages/PricingPageTwo";
// import ShopPageOne from "../components/Pages/ShopPageOne";
// import ShopPageTwo from "../components/Pages/ShopPageTwo";
// import CartPage from "../components/Pages/CartPage";
// import CheckoutPage from "../components/Pages/CheckoutPage";
// import ShopDetailsPage from "../components/Pages/ShopDetailsPage";
// import FeaturesPageOne from "../components/Pages/FeaturesPageOne";
// import FeaturesPageTwo from "../components/Pages/FeaturesPageTwo";
// import TestimonialsPage from "../components/Pages/TestimonialsPage";
// import LoginPage from "../components/Pages/LoginPage";
// import RegisterPage from "../components/Pages/RegisterPage";
// import ForgotPasswordPage from "../components/Pages/ForgotPasswordPage";
// import ErrorPage from "../components/Pages/ErrorPage";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
// import ServicesPageOne from "../components/Pages/ServicesPageOne";
// import ServicesPageTwo from "../components/Pages/ServicesPageTwo";
// import ServicesPageThree from "../components/Pages/ServicesPageThree";
// import ServicesDetailsPage from "../components/Pages/ServicesDetailsPage";
// import PortfolioPageOne from "../components/Pages/PortfolioPageOne";
// import PortfolioPageTwo from "../components/Pages/PortfolioPageTwo";
// import PortfolioPageThree from "../components/Pages/PortfolioPageThree";
// import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage";
// import BlogPageOne from "../components/Pages/BlogPageOne";
// import BlogPageTwo from "../components/Pages/BlogPageTwo";
// import BlogPageThree from "../components/Pages/BlogPageThree";
// import BlogDetailsPage from "../components/Pages/BlogDetailsPage";

// import ContactPageTwo from "../components/Pages/ContactPageTwo";

const routes = [
  { path: "/", component: Home },
  { path: "/Overview", component: Overview },
  { path: "/Assessments", component: Assessments },
  { path: "/CrosswalkDashboard", component: CrosswalkDashboard },
  { path: "/VulnerabilityManagement", component: VulnerabilityManagement },
  { path: "/TaskManagement", component: TaskManagement },
  { path: "/ThirdPartyRisk", component: ThirdPartyRisk },
  { path: "/PolicyAutomator", component: PolicyAutomator },
  { path: "/EvidenceManagement", component: EvidenceManagement },
  { path: "/about", component: About },
  { path: "/pricing", component: Pricing },
  { path: "/portfolio", component: Portfolio },
  { path: "/faq", component: FaqPage },
  { path: "/contact", component: Contact },
  // The routes above are in use

  // { path: "/", component: HomePageOne },
  // { path: "/home-two", component: HomePageTwo },
  // { path: "/home-three", component: HomePageThree },
  // { path: "/home-four", component: HomePageFour },
  // { path: "/home-five", component: HomePageFive },
  // { path: "/about-one", component: AboutPageOne },
  // { path: "/team-one", component: TeamPageOne },
  // { path: "/team-two", component: TeamPageTwo },
  // { path: "/pricing-two", component: PricingPageTwo },
  // { path: "/shop-one", component: ShopPageOne },
  // { path: "/shop-two", component: ShopPageTwo },
  // { path: "/cart", component: CartPage },
  // { path: "/checkout", component: CheckoutPage },
  // { path: "/shop-details", component: ShopDetailsPage },
  // { path: "/features-one", component: FeaturesPageOne },
  // { path: "/features-two", component: FeaturesPageTwo },
  // { path: "/testimonials", component: TestimonialsPage },
  // { path: "/login", component: LoginPage },
  // { path: "/register", component: RegisterPage },
  // { path: "/forgot-password", component: ForgotPasswordPage },
  // { path: "/error-404", component: ErrorPage },
  { path: "/terms-of-service", component: TermsOfServicePage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  // { path: "/services-one", component: ServicesPageOne },
  // { path: "/services-two", component: ServicesPageTwo },
  // { path: "/services-three", component: ServicesPageThree },
  // { path: "/services-details", component: ServicesDetailsPage },
  // { path: "/portfolio-one", component: PortfolioPageOne },
  // { path: "/portfolio-two", component: PortfolioPageTwo },
  // { path: "/portfolio-three", component: PortfolioPageThree },
  // { path: "/portfolio-details", component: PortfolioDetailsPage },
  // { path: "/blog-one", component: BlogPageOne },
  // { path: "/blog-two", component: BlogPageTwo },
  // { path: "/blog-three", component: BlogPageThree },
  // { path: "/blog-details", component: BlogDetailsPage },
  // { path: "/contact-two", component: ContactPageTwo },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
