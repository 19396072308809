<template>
  <div class="subscribe-area ptb-100">
    <div class="container">
      <div class="subscribe-content">
        <h2>Get Our Product Updates</h2>

        <form class="newsletter-form" data-toggle="validator">
          <input
            type="email"
            class="input-newsletter"
            placeholder="Enter your email"
            name="EMAIL"
            required
          />

          <button type="submit">Subscribe</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Subscribe",
};
</script>
