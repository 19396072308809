<template>
  <div class="about-area pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-image">
            <img src="../../assets/images/about/about-1.png" alt="image" />
          </div>
        </div>

        <div class="col-lg-6">
          <div class="about-content">
            <span>Necessity</span>
            <h2 style="font-size: 35px;">We Empower Companies of All Sizes</h2>
            <div class="bar"></div>
            <p>
              Because visibility into the overall status of security and
              compliance is so critical for companies of all sizes, we decided
              to create a solution with capabiities that seemed to be lacking,
              or unaffordable, in today’s marketplace.
            </p>
            <p>
              From managing Internal/External Assessments to Third-Party
              Organization risk, the platform was created with two goals in
              mind: make it <b><i>easier</i></b
              >, and make it <b><i>better</i></b
              >.
            </p>

            <!-- <div class="about-btn">
              <router-link to="/about-one" class="default-btn">
                Learn More
              </router-link>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Necessity",
};
</script>
