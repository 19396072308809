<template>
  <div class="faq-area ptb-100">
    <div class="container">
      <div class="section-title">
        <span>FAQs</span>
        <h2 style="font-size: 35px;">Frequently Asked Questions</h2>
        <div class="bar"></div>
      </div>

      <div class="faq-accordion">
        <accordion>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                How much does the platform cost?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                That really depends on what you need. We offer several
                affordable pricing plans, and we'll work together to determine
                what's sustainable and scalable.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                How can I see a demo?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Simply reach out by phone or email, tell us your primary areas
                of interest, and we'll schedule a demo at your convenience with
                you and/or your team.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                How much is training and support?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Free, as long as you take advantage of "The First 30" training
                plan within the first 30 days.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                Can I Cancel My Subscription?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Yes, as long as you've completed "The First 30" training plan
                within the first 30 days.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                Can I request new features?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Definitely! Our roadmap is determined by what our customers want
                to see most.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                Do you support integrations with other tools?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Yes! We offer several integrations with specific products that
                share our security and privacy standards.
              </p>
            </template>
          </accordion-item>
          <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                What if i need extra help getting started?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                We'll meet you where you are, just let us know. We're not
                successful unless you are.
              </p>
            </template>
          </accordion-item>

          <!-- <accordion-item>
            <template v-slot:accordion-trigger>
              <button class="accordion-title">
                Do I Need A Blog?
                <i class="bx bx-plus"></i>
              </button>
            </template>
            <template v-slot:accordion-content>
              <p>
                Digital marketing involves marketing to people using
                Internet-connected electronic devices, namely computers,
                smartphones and tablets. Digital marketing focuses on channels
                such as search engines, social media, email, websites and apps
                to connect with prospects and customers.
              </p>
            </template>
          </accordion-item> -->
        </accordion>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
  name: "FaqContent",
  components: {
    Accordion,
    AccordionItem,
  },
};
</script>
