<template>
  <div>
    <Navbar />
    <!-- <PageBanner /> -->
    <ContactDetails />
    <!-- <Subscribe /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
// import PageBanner from "../Contact/PageBanner";
import ContactDetails from "../Contact/ContactDetails";
// import Subscribe from "../Contact/Subscribe";
import Footer from "../Layout/Footer";

export default {
  name: "Contact",
  components: {
    Navbar,
    // PageBanner,
    ContactDetails,
    // Subscribe,
    Footer,
  },
};
</script>
