<template>
  <div>
    <div class="about-area ptb-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6">
            <div style="padding: 35px">
              <!-- <div class="rounded mx-auto d-block; height: 300px"> -->
              <img
                src="../../assets/images/about/orangeballs.jpg"
                alt="image"
              />
            </div>
            <!-- <div class="about-image-warp image-three">
              <div
                class="video-btn popup-youtube"
                v-for="(image, imageIndex) in items"
                :key="imageIndex"
                @click="index = imageIndex"
                style="cursor: pointer"
              >
                <i class="bx bx-play"></i>
              </div>
            </div> -->
          </div>

          <div class="col-lg-6">
            <div class="about-content warp">
              <span>Our Mindset</span>
              <h3>Innovation is Key</h3>
              <div class="bar"></div>
              <strong
                >Impact Digital Labs is a virtual full-stack development shop,
                born out of the need to create highly effective and affordable
                compliance tools. Having backgrounds in cybersecurity, risk, and
                compliance, we align with industry best practices as we strive
                to encourage visibility and collaboration.</strong
              >
              <p>
                As a small business with big business roots, we understand the
                challenges - from start-up growing pains, to trying to retain
                efficiencies and productivity as a corporate behemoth. We'll
                meet you where you are, because
                <b><i>agility is our superpower.</i></b>
              </p>

              <div class="about-inner-content">
                <div class="icon">
                  <i class="flaticon-check"></i>
                </div>
                <h4>Tested & Proven</h4>
                <p>
                  Our Leadership Team brings years of corporate fairy dust,
                  coming from leadership roles across several industries. We've
                  been there, done that, and on a good day, we got the T-shirt.
                  And that's why we're so confident in our products - we develop
                  tools we want to use (or wish we'd had).
                </p>
              </div>

              <div class="about-inner-content">
                <div class="icon">
                  <i class="flaticon-check"></i>
                </div>
                <h4>No 'O'</h4>
                <p>
                  We believe in contolling the quality and integrity of our
                  products and relationships, so we don't Outsource or Offshore
                  any piece of our business. Plus, we just like to know with
                  whom we're trusting our most valuable asset - you.
                </p>
              </div>
              <div class="about-inner-content">
                <div class="icon">
                  <i class="flaticon-check"></i>
                </div>
                <h4>Silver Linings</h4>
                <p>
                  We know one of the downsides to being a small business is that
                  we don't have a fancy sales and marketing team. The upside? WE
                  DON'T HAVE A FANCY SALES AND MARKETING TEAM! It's just us,
                  focused on supporting you, in every way we can.
                </p>
              </div>

              <div class="about-inner-content">
                <div class="icon">
                  <i class="flaticon-check"></i>
                </div>
                <h4>It's Totally Personal</h4>
                <p>
                  Our Leadership Team not only has the experience and
                  credentials, we enjoy a friendship that stands the test of
                  time and change. Through working together on and off in
                  various capacities for more than 15 years, our core values as
                  people, and our shared drive and work ethic, have never
                  changed.
                </p>
              </div>

              <div class="about-btn">
                <!-- <router-link to="/about-one" class="default-btn">
                  Learn More
                </router-link> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Mindset",
};
</script>
