<template>
  <div class="main-banner">
    <div class="main-banner-item item-four">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
          <!-- <div class="container-fluid"> -->
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div
                  class="main-banner-image"
                  style="margin-left: 0px; padding: 5px"
                >
                  <img
                    src="../../assets/images/banner/banner-image-1.png"
                    alt="image"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div
                  class="main-banner-content"
                  style=" padding: 50px"
                >
                  <h2 style="font-size: 35px;">Get Answers</h2>
                  <p>
                    Our goal is to offer a product that gives you everything you
                    really need, and nothing you don't.
                  </p>
                  <br />
                  <p>
                    Learn a little more about Impact IRM, and get answers to
                    some frequently asked questions below.
                  </p>
                  <div class="banner-btn">
                    <router-link to="/contact" class="default-btn">
                      Get Started
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>
