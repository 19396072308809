<template>
  <div class="contact-area pt-100">
    <div class="container mb-5">
      <div class="section-title">
        <h2>Ready to Power Up?</h2>
        <div class="bar"></div>
      </div>

      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="contact-image">
            <img src="../../assets/images/about/tiredlady.jpg" alt="image" />
          </div>
        </div>

        <!-- <div class="col-lg-5">
          <div class="contact-form-inner">
            <form
              action="https://impactirm.us9.list-manage.com/subscribe/post?u=e1c06dd1c27803ac3df55a2a2&amp;id=a6cea95774&amp;f_id=00ec0ce1f0"
              id="contactForm"
              method="post"
              name="mc-embedded-subscribe-form"
              class="validate"
              target="_blank"
              novalidate
            >
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="FNAME"
                      id="mce-FNAME"
                      placeholder="Name"
                      value=""
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="email"
                      name="EMAIL"
                      id="mce-EMAIL"
                      placeholder="Email"
                      value=""
                      class="form-control"
                      required
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="PHONE"
                      id="mce-PHONE"
                      placeholder="Phone"
                      value=""
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="SUBJECT"
                      id="mce_SUBJECT"
                      placeholder="Subject"
                      value=""
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <textarea
                      name="MESSAGE"
                      class="form-control"
                      id="mce_MESSAGE"
                      placeholder="Your Message"
                      value=""
                      cols="30"
                      rows="5"
                    ></textarea>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12">
                  <button
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    class="default-btn"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div> -->
        <div class="col-lg-5">
          <div class="container">
            <div class="general-contact-info pt-0 pb-0">
              <div class="contact-info-content pt-0 pb-0">
                <h3>Reach us by Phone or Email</h3>
                <h2>
                  <a href="tel:+1404.382.8191">404.382.8191</a>
                  <span>OR</span>
                  <a href="mailto:team@impactdigitallabs.com">Team@ImpactDigitalLabs.com</a>
                </h2>

                <!-- <ul class="social">
            <li>
              <a href="https://www.twitter.com/" target="_blank"
                ><i class="bx bxl-twitter"></i
              ></a>
            </li>
            <li>
              <a href="https://www.youtube.com/" target="_blank"
                ><i class="bx bxl-youtube"></i
              ></a>
            </li>
            <li>
              <a href="https://www.facebook.com/" target="_blank"
                ><i class="bx bxl-facebook"></i
              ></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/" target="_blank"
                ><i class="bx bxl-linkedin"></i
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank"
                ><i class="bx bxl-instagram"></i
              ></a>
            </li>
          </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="services-area bg-fafafa"
      style="padding-bottom: 50px; background-color: #fafafa"
    >
      <div class="container">
        <div class="general-contact-info pt-0 pb-0">
          <div class="contact-info-content pt-0 pb-0">
            <h3>Reach us by Phone or Email</h3>
            <h2>
              <a href="tel:+1404.382.8191">404.382.8191</a>
              <span>OR</span>
              <a href="mailto:team@impactirm.com">team@impactirm.com</a>
            </h2>
          </div>
        </div>
      </div>
      <div class="default-shape">
        <div class="shape1">
          <img
            src="../../assets/images/default-shape/default-shape1.png"
            alt="image"
          />
        </div>
        <div class="shape2">
          <img
            src="../../assets/images/default-shape/default-shape2.png"
            alt="image"
          />
        </div>
        <div class="shape3">
          <img
            src="../../assets/images/default-shape/default-shape3.png"
            alt="image"
          />
        </div>
        <div class="shape4">
          <img
            src="../../assets/images/default-shape/default-shape4.png"
            alt="image"
          />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ContactDetails",
};
</script>
