<template>
  <div class="features-area pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="features-inner-content">
            <span>Our Team</span>
            <h2 style="font-size: 35px;">Street Cred</h2>
            <div class="bar"></div>
            <p>
              Impact IRM's creator is a recovering executive from
              publicly-traded companies in the telecom industry, and the former
              CSO of a $50 billion FinTech company.
            </p>
            <br />
            <p>
              Our talented leadership team brings years of technical expertise
              and leverages the following credentials, among others:
            </p>
            <ul class="features-list">
              <li>
                <span><i class="flaticon-checked"></i> CISA</span>
              </li>
              <li>
                <span><i class="flaticon-checked"></i> CISSP</span>
              </li>
              <li>
                <span><i class="flaticon-checked"></i> AWS Certifications</span>
              </li>
              <li>
                <span><i class="flaticon-checked"></i> PCI-DSS QSA</span>
              </li>

              <li>
                <span><i class="flaticon-checked"></i> M.S. Cybersecurity</span>
              </li>
              <li>
                <span
                  ><i class="flaticon-checked"></i> Karate, 2nd Degree Black
                  Belt</span
                >
              </li>
            </ul>

            <div class="features-btn">
              <router-link to="/about" class="gradient-btn">
                Learn More
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="features-image">
            <img
              src="../../assets/images/features/features-1.png"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StreetCred",
};
</script>
