<template>
  <div>
    <Navbar />
    <!-- <PageBanner /> -->
    <AssessmentsContent />
    <HelpingBusinesses />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
// import PageBanner from "../Platform/PageBanner";
import AssessmentsContent from "../Platform/AssessmentsContent";
import HelpingBusinesses from "../Common/HelpingBusinesses";
import Footer from "../Layout/Footer";

export default {
  name: "Assessments",
  components: {
    Navbar,
    // PageBanner,
    AssessmentsContent,
    HelpingBusinesses,
    Footer,
  },
};
</script>
