<template>
    <div class="portfolio-area pt-100 pb-100">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-1.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">Creative Work</router-link>
                            </h3>
                            <span>Web Design</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-2.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">Ecommerce Work</router-link>
                            </h3>
                            <span>Creative Agency</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-3.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">App Development</router-link>
                            </h3>
                            <span>IOS Android</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-4.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">Dashboard App</router-link>
                            </h3>
                            <span>Web Development</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-5.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">Web Application</router-link>
                            </h3>
                            <span>Marketing</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-6.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">Logo & Branding</router-link>
                            </h3>
                            <span>Agency</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-7.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">Web Development</router-link>
                            </h3>
                            <span>Explanation</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-portfolio-item">
                        <div class="portfolio-image">
                            <router-link to="/portfolio-details">
                                <img src="../../assets/images/portfolio/portfolio-8.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="portfolio-content">
                            <h3>
                                <router-link to="/portfolio-details">Creative Web Design</router-link>
                            </h3>
                            <span>Solutions</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <a href="#" class="prev page-numbers">
                            <i class="flaticon-left-arrow"></i>
                        </a>
                        <a href="#" class="page-numbers">1</a>
                        <span class="page-numbers current" aria-current="page">2</span>
                        <a href="#" class="page-numbers">3</a>
                        <a href="#" class="page-numbers">4</a>
                        <a href="#" class="next page-numbers">
                            <i class="flaticon-next"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape1">
                <img src="../../assets/images/default-shape/default-shape1.png" alt="image">
            </div>
            <div class="shape2">
                <img src="../../assets/images/default-shape/default-shape2.png" alt="image">
            </div>
            <div class="shape3">
                <img src="../../assets/images/default-shape/default-shape3.png" alt="image">
            </div>
            <div class="shape4">
                <img src="../../assets/images/default-shape/default-shape4.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PortfolioContent',
}
</script>