<template>
  <div class="features-area pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="about-content">
            <span
              style="
                color: #ff3656;
                font-weight: bold;
                text-transform: uppercase;
              "
              >Technology</span
            >
            <h2 style="font-size: 35px;">Key Differentiators</h2>
            <div class="bar"></div>
            <p>Impact IRM offers the most in securing your data.</p>
          </div>
          <div class="about-differentiators">
            <ul class="features-list">
              <li>
                <span>
                  <!-- <i class="flaticon-check-mark" style="color:green; background-color:blue"> -->
                  <i class="flaticon-check-mark" style="color: white"> </i> Data
                  Isolation</span
                >
              </li>
              <li>
                <span>
                  <i class="flaticon-check-mark" style="color: white"> </i>
                  DevSecOps & IaC</span
                >
              </li>
              <li>
                <span>
                  <i class="flaticon-check-mark" style="color: white"> </i>
                  Dedicated Servers</span
                >
              </li>
              <li>
                <span>
                  <i class="flaticon-check-mark" style="color: white"> </i>SBOM
                  Transparency</span
                >
              </li>
              <li>
                <span>
                  <i class="flaticon-check-mark"> </i> Dedicated Databases</span
                >
              </li>
              <li>
                <span>
                  <i class="flaticon-check-mark" style="color: white"> </i>
                  Kubernetes</span
                >
              </li>
              <li>
                <span>
                  <i class="flaticon-check-mark" style="color: white"> </i>
                  Dedicated Storage</span
                >
              </li>

              <li>
                <span>
                  <i class="flaticon-check-mark" style="color: white"> </i>
                  Infrastructure as Code (IaC)</span
                >
              </li>
            </ul>

            <!-- <div class="features-btn">
                            <router-link to="/about-one" class="default-btn">
                                Learn More
                            </router-link>
                        </div> -->
          </div>
        </div>

        <div class="col-lg-6">
          <div class="features-image">
            <img
              src="../../assets/images/login-form.png"
              alt="image"
              max-width="200%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyDifferentiators",
};
</script>
