<template>
  <div>
    <Navbar />
    <FaqBanner />
    <FaqContent />
    <!-- <FaqContact />
    <GetOnlyNewUpdate /> -->
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import FaqBanner from "../Faq/FaqBanner";
import FaqContent from "../Faq/FaqContent";
// import FaqContact from "../Faq/FaqContact";
// import GetOnlyNewUpdate from "../Common/GetOnlyNewUpdate";
import Footer from "../Layout/Footer";

export default {
  name: "FaqPage",
  components: {
    Navbar,
    FaqBanner,
    FaqContent,
    // FaqContact,
    // GetOnlyNewUpdate,
    Footer,
  },
};
</script>
