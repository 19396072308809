<template>
  <div class="main-banner">
    <div class="main-banner-item item-four">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="main-banner-content">
            <h2 style="font-size: 55px;">Control the Narrative</h2>
            <span
                style="
                color: #085bdd;
                font-weight: bold;
                font-size:150%;
                text-transform: uppercase;
              "
              >Impact IRM</span>
                  <p>
                    Get visibility into your organization's compliance on your
                    terms - as often (or as little) as you need it.
                  </p>
                  <br />
                  <p>
                    Impact Integrated Risk Management (IRM) software is a
                    consolidated, cloud-based platform that provides
                    collaborative, increased visibility into the data and
                    processes that affect organizations’ overall security and
                    compliance.
                  </p>
                  <div class="banner-btn">
                    <router-link to="/contact" class="default-btn">
                      Get Started
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="main-banner-image">
                  <img
                    src="../../assets/images/features/features-2.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
};
</script>
