<template>
  <div class="page-banner-area item-bg1">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="page-banner-content">
            <h2>Privacy Policy</h2>
            <ul>
              <li>
                <router-link to="/">Home</router-link>
              </li>
              <li>Privacy Policy</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageBanner",
};
</script>
