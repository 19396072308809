<template>
  <div>
    <Navbar />
    <!-- <PageBanner /> -->
    <PortfolioContent />
    <Footer />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
// import PageBanner from "../Portfolio/PageBanner";
import PortfolioContent from "../Portfolio/PortfolioContent";
import Footer from "../Layout/Footer";

export default {
  name: "Portfolio",
  components: {
    Navbar,
    // PageBanner,
    PortfolioContent,
    Footer,
  },
};
</script>
