<template>
  <div class="services-area bg-fafafa pt-100 pb-70">
    <div class="container">
      <div class="section-title">
        <span>More Benefits</span>
        <h2 style="font-size: 35px;">A Different Kind of SaaS</h2>
        <div class="bar"></div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-code"></i>
            </div>

            <h3>
              <!-- <router-link to="/services-details"> -->
              White-Labeling
              <!-- </router-link> -->
            </h3>
            <p>
              Brand your instance with your company's look and feel. If you're
              working with clients, our white-label offering presents the
              platform as your own.
            </p>
            <!-- <router-link to="/services-details" class="services-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-vector"></i>
            </div>

            <h3>
              <!-- <router-link to="/services-details"> -->
              GovCloud
              <!-- </router-link> -->
            </h3>
            <p>
              We're pleased to offer GovCloud to those who require the highest
              level of security - meeting the most stringent requirements.
            </p>
            <!-- <router-link to="/services-details" class="services-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link> -->
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-services-box">
            <div class="icon">
              <i class="flaticon-network"></i>
            </div>

            <h3>
              <!-- <router-link to="/services-details"> -->
              Partnership Models
              <!-- </router-link> -->
            </h3>
            <p>
              Take advantage of our Value Added Reseller (VAR) and Referring
              Partner relationships that can provide additional revenue.
            </p>
            <!-- <router-link to="/services-details" class="services-btn"
              >Read More <i class="flaticon-right-arrow"></i
            ></router-link> -->
          </div>
        </div>
      </div>
    </div>

    <div class="default-shape">
      <div class="shape1">
        <img
          src="../../assets/images/default-shape/default-shape1.png"
          alt="image"
        />
      </div>
      <div class="shape2">
        <img
          src="../../assets/images/default-shape/default-shape2.png"
          alt="image"
        />
      </div>
      <div class="shape3">
        <img
          src="../../assets/images/default-shape/default-shape3.png"
          alt="image"
        />
      </div>
      <div class="shape4">
        <img
          src="../../assets/images/default-shape/default-shape4.png"
          alt="image"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MoreBenefits",
};
</script>
