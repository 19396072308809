<template>
  <div class="privacy-policy-area ptb-100">
    <div class="container">
      <div class="privacy-content">
        <p
          class="MsoNormal"
          style="
            margin-top: 7.5pt;
            margin-right: 0in;
            margin-bottom: 7.5pt;
            margin-left: 0in;
            mso-outline-level: 5;
            background: white;
          "
        >
          <b
            ><u
              ><span
                style="
                  font-size: 10.5pt;
                  font-family: 'Open Sans', sans-serif;
                  mso-fareast-font-family: 'Times New Roman';
                  color: #555555;
                "
                >PRIVACY POLICY</span
              ></u
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >1. INTRODUCTION</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Welcome to Impact Digital Labs,
            <span class="GramE"
              >Inc <span style="mso-spacerun: yes"> </span>(</span
            >herein referred to as the “Company”, “we”, “us” or “our”). Company
            is a provider of &quot;various compliance, risk management and
            cybersecurity related services” (Services). Your use of our Services
            is subject to our Terms of Service, which include our Acceptable Use
            Policy, and any capitalized term used but not defined here shall
            have the meaning attributed to it therein.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >As you engage with our <span class="GramE">Service</span> we may
            collect some personal data from you. This privacy policy (the
            “Privacy Policy”) explains our data practices and your choices
            regarding personal data and other information that we collect in
            connection with your use of the Company website(s) located at:
            https://Impact-Labs.io, and/or other websites facilitating access to
            the Company’s Service (each, together with its sub-domains, content
            and services, the “Site”). The Site together with the Services are
            referred to as the Platforms. The Privacy Policy forms part of our
            Terms of Service.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >By using the <span class="GramE">Platforms, and</span> providing us
            with your personal data through your use of the Platforms, you agree
            to the practices described in this Privacy Policy. If you do not
            agree to this Privacy Policy, please do not
            <span class="GramE">access</span> or otherwise use the
            Platforms.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >2. INFORMATION WE COLLECT</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >When you interact with the Platforms, we may collect information
            that, alone or in combination with other information, could be used
            to identify you (“Personal Data”), as described below:<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Personal Data That You Provide Through the Platforms: When you
            request information, including a demo, ask to download content,
            register for an account, or subscribe to emailing lists, we may
            collect the following Personal Data from you:<o:p></o:p
          ></span>
        </p>

        <ul type="disc">
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >First and last name<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Job title<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Company name<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Email address<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Mailing address<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Phone number<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Country location<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l0 level1 lfo1;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Your subscription <span class="GramE">preferences, if</span> you
              choose to opt-in to receive promotional emails from us<o:p></o:p
            ></span>
          </li>
        </ul>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >If you would like to discontinue receiving information from us, you
            may update your email preferences by using the ‘unsubscribe’ link in
            emails or by contacting team@Impact-Labs.io<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Automatically Collected Data: When you visit the Platforms, we may
            automatically collect information about you through cookies and
            similar technologies. Please see the “Cookies and Other Tracking
            Technologies” section below to learn more about how we use cookies
            and similar technologies. We collect information such as your
            Internet Protocol (IP) address, Internet Service Provider (ISP),
            browser type, files viewed on our Site (<span class="GramE"
              >e.g.</span
            >
            HTML pages or graphics), operating system, and clickstream data. We
            also collect information on the country you are connecting from
            based on your IP address at the time you visit the Platforms.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Data Collected <span class="GramE">On</span> Behalf of Customers:
            We also collect information under the direction of our Customers,
            and have no direct relationship with the individuals whose Personal
            Data we process on behalf of Customers. If you are a client or user
            of one of our <span class="GramE">Customers</span> and would no
            longer like to be contacted by one of our Customers that use our
            Services or you would like to access, correct or request deletion of
            your data, please contact the Customer that you interact with
            directly.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >3. HOW WE USE PERSONAL DATA AND OTHER INFORMATION</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >To provide the Services to you and respond to your requests. When
            you register for an Account, ask for information about the Services,
            or, for example, ask to download content through the Platforms, we
            may use your contact information and login credentials.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >For marketing purposes. We may use your email or mail address to
            send you information (as applicable) by email about our new products
            and services, upcoming <span class="GramE">events</span> or other
            promotions. You may opt-out of receiving such emails by following
            the instructions contained in each promotional email we send you or
            by contacting us at team@Impact-Labs.io.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >To analyze, administer, support, improve use of the Platforms. We
            use data relating to your use of the Platforms to analyze,
            administer, <span class="GramE">support</span> and improve your
            access to and use of the Platforms. We may also compile, anonymize
            and/or aggregate your Personal Data and other data and use such
            anonymized and/or aggregated data for our business purposes,
            including sharing it with affiliates and business partners.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >4. SHARING AND DISCLOSURE</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >We may share your Personal Data and other information with certain
            third parties without further notice to you, as set forth below:<o:p
            ></o:p
          ></span>
        </p>

        <ul type="disc">
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l3 level1 lfo2;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Vendors and Service Providers: We may share your information with
              third parties who provide services on our behalf
              <span class="GramE">in order to</span> assist us in meeting
              business operations needs and to perform certain services and
              functions. Pursuant to our instructions, these parties may only
              access, process or store Personal<o:p></o:p
            ></span>
          </li>
        </ul>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Data <span class="GramE">in the course of</span> performing their
            duties to us.<o:p></o:p
          ></span>
        </p>

        <ul type="disc">
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l2 level1 lfo3;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Business Transfers: If we are involved in a merger, acquisition,
              financing due diligence, reorganization, bankruptcy, receivership,
              sale of company assets, or transition of service to another
              provider, your Personal Data and other information may be
              transferred to a successor or affiliate as part of that
              transaction along with other assets.<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l2 level1 lfo3;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Legal Requirements: We may disclose your Personal Data if
              required to do so by law, such as to comply with a subpoena or
              other legal process, or in the good faith belief that such action
              is necessary to: (<span class="SpellE">i</span>) comply with a
              legal obligation or governmental request, (ii) protect and defend
              the rights or property of Company, (iii) act in urgent
              circumstances to protect the personal safety of users of the
              Platforms or the public, or (iv) protect against legal
              liability.<o:p></o:p
            ></span>
          </li>
        </ul>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >5. DATA RETENTION</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >We may keep your Personal Data for as long as your account is
            active or as needed to provide you or our Services, comply with our
            legal obligations, resolve disputes, and enforce our agreements.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >6. UPDATE YOUR INFORMATION</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >If you need to change or correct your Personal
            <span class="GramE">Data, or</span> wish to have your Personal Data
            removed from our systems, please contact us as described in the
            “Contact Us” section below and we will address your requests as
            required by applicable law.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >7. CALIFORNIA DO NOT TRACK DISCLOSURES</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >We and our <span class="GramE">third party</span> partners use
            cookies or other technologies on our Site or Platforms that collect
            information about your browsing activities over time and across
            different websites following your use of the Site or Platforms (for
            more information, please see the “Cookies and Other Tracking
            Technologies” section below). We currently do not respond to “Do Not
            Track” (DNT) signals and operate as described in this Privacy Policy
            <span class="GramE">whether or not</span> a DNT signal is
            received.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >8. CHILDREN</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Company does not knowingly collect Personal Data from children
            under the age of 13. If you have reason to believe that a child
            under the age of 13 has provided Personal Data to Company through
            the Platforms, please contact us at team@Impact-Labs.io and we will
            endeavor to delete that information from our databases.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >9. LINKS TO OTHER WEBSITES</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >The Platforms may contain links to other websites not operated or
            controlled by us (“Third Party Sites”), including social media
            websites and services. The information that you share with Third
            Party Sites, including Personal Data you choose to share, may be
            governed by the specific privacy policies and terms of service of
            the <span class="GramE">Third Party</span> Sites and not by this
            Privacy Policy. By providing these links we do not imply that we
            endorse or have reviewed these sites. Please contact those sites
            directly for information on their privacy practices and policies. We
            encourage you to carefully read the privacy policy of any website
            you visit.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >10. SECURITY</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >We take reasonable administrative and technical steps to protect
            the Personal Data provided via the Platforms from loss, misuse and
            unauthorized access, disclosure, alteration, or destruction both
            during transmission and once it is received. However, the Internet
            cannot be guaranteed to be fully
            <span class="GramE">secure</span> and we cannot ensure or warrant
            the security of any information you provide to us. Please keep this
            in mind when providing us with your Personal Data.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >11. COOKIES AND OTHER TRACKING TECHNOLOGIES</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >We use cookies from third parties to optimize the functionality of
            the website, help us understand how the Site is used and provide you
            with interest-based advertising based upon a user’s browsing
            activities and interests. A cookie is a piece of information sent to
            your browser from a website and stored on your computer’s hard
            drive. Cookies can help a website like ours recognize repeat users
            and allow a website to track web usage behavior. Cookies work by
            assigning a number to the user that has no meaning outside of the
            assigning website. We use cookies to collect information about your
            device, such as IP address,
            <span class="GramE">operating system</span> and browser type, in
            addition to the information described below.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >The Site also uses pixel tags, a technology
            <span class="GramE">similar to</span> cookies that is placed on a
            website or within the body of an email for the purpose of tracking
            activity on websites, or when emails are opened or accessed, and is
            often used in combination with cookies. The Site uses pixels tags,
            from the below third parties, to help us improve use of our Site and
            the <span class="GramE">services, and</span> know when content has
            been shown to you.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Technologies Used<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >The types of technologies that we use are detailed below:<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Analytics. The Site uses “analytical” cookies that allow us to
            recognize and count the number of visitors and to see how visitors
            move around the Site when they are using it. This helps us to
            improve the way our Site works, for example by making sure visitors
            are finding what they need easily. The information collected through
            these cookies include anonymous traffic statistics, like number of
            page views, number of visitors, and time spent on each page.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >In particular, the Site uses: Google Analytics&nbsp;<br />
            For more information on Google Analytics and the information it
            collects, please visit&nbsp;https://policies.google.com/privacy.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Advertising. We partner with one or more third parties to either
            display advertising on our Site or to manage our advertising on
            other sites. Our <span class="GramE">third party</span> partner(s)
            use technologies such as cookies and pixel tags to gather
            information about your activities on the Site and other websites to
            provide you with targeted advertising based upon your browsing
            activities and interests. In particular, the Site uses: Google
            Ads&nbsp;<br />
            For more information on Google Ads and the information it collects,
            please visit&nbsp;https://policies.google.com/technologies.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >On most web browsers, you may find a “help” section on the
            toolbar. Please refer to this section for information on how to
            receive a notification when you are receiving a new cookie and how
            to turn cookies off. Please see the links below for guidance on how
            to modify your web browser’s settings on the most popular
            browsers:<o:p></o:p
          ></span>
        </p>

        <ul type="disc">
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l1 level1 lfo4;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Internet Explorer<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l1 level1 lfo4;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Mozilla Firefox<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l1 level1 lfo4;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Google Chrome<o:p></o:p
            ></span>
          </li>
          <li
            class="MsoNormal"
            style="
              color: #555555;
              mso-margin-top-alt: auto;
              mso-margin-bottom-alt: auto;
              mso-list: l1 level1 lfo4;
              tab-stops: list 0.5in;
              background: white;
            "
          >
            <span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
              "
              >Apple Safari<o:p></o:p
            ></span>
          </li>
        </ul>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Please note that if you reject cookies or turn cookies off, you may
            be unable to access certain parts of the Site and you may not be
            able to benefit from the full functionality of the Site.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >To find out more about cookies and similar technologies, including
            how to see what cookies and similar technologies have been set and
            how to manage and delete them, visit: http://www.allaboutcookies.org
            and/or the Network Advertising Initiative’s online resources, at:
            http://www.networkadvertising.org, and follow the opt-out
            instructions there. If you access the Site on your mobile device,
            you may not be able to control tracking technologies through the
            settings.<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >12. CHANGES TO OUR PRIVACY POLICY</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >We may change this Privacy Policy at any time and when we do, we
            will post an updated version on this page. If we make any material
            <span class="GramE">changes</span> we will notify you prior to the
            change becoming effective. By continuing to use the Platforms or
            providing us with information after we have posted an updated
            Privacy Policy you consent to the revised Privacy Policy and
            practices described in it. We encourage you to periodically review
            this page for the latest information on our privacy practices.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <b
            ><span
              style="
                font-size: 10.5pt;
                font-family: 'Open Sans', sans-serif;
                mso-fareast-font-family: 'Times New Roman';
                color: #555555;
              "
              >13. CONTACT US</span
            ></b
          ><span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            ><o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >Please feel free to contact us if you have any questions about our
            Privacy Policy or the information practices of our Platforms.<o:p
            ></o:p
          ></span>
        </p>

        <p class="MsoNormal" style="margin-bottom: 7.5pt; background: white">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >You may contact us as follows:&nbsp;<o:p></o:p
          ></span>
        </p>

        <p class="MsoNormal">
          <span
            style="
              font-size: 10.5pt;
              font-family: 'Open Sans', sans-serif;
              mso-fareast-font-family: 'Times New Roman';
              color: #555555;
            "
            >team@Impact-Labs.io.</span
          >
        </p>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "PrivacyPolicyContent",
};
</script>
