<template>
    <div>
        <Navbar />
        <PageBanner />
        <TermsOfServiceContent />
        <GetOnlyNewUpdate />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageBanner from '../TermsOfService/PageBanner'
import TermsOfServiceContent from '../TermsOfService/TermsOfServiceContent'
import GetOnlyNewUpdate from '../Common/GetOnlyNewUpdate'
import Footer from '../Layout/Footer'

export default {
    name: 'TermsOfServicePage',
    components: {
        Navbar,
        PageBanner,
        TermsOfServiceContent,
        GetOnlyNewUpdate,
        Footer,
    }
}
</script>
