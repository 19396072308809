<template>
  <div>
    <Navbar />
    <!-- <AboutBanner /> -->
    <Mindset />
    <!-- <Team /> -->
    <Testimonials />
    <Footer />
    <!-- <Partner class="pt-100" /> -->
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
// import AboutBanner from "../About/AboutBanner";
import Mindset from "../About/Mindset";
// import Team from "../About/Team";
import Testimonials from "../About/Testimonials";
import Footer from "../Layout/Footer";
// import Partner from "../Common/Partner";

export default {
  name: "About",
  components: {
    Navbar,
    // AboutBanner,
    Mindset,
    // Team,
    Testimonials,
    Footer,
    // Partner,
  },
};
</script>
